// import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

/** @jsx jsx */
import { Container, jsx } from 'theme-ui'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Non trouvé" />

    <Container>
      <div sx={{
          py: 6,
      }}>    
        <h1>Oups! Erreur 404</h1>
        <p>Cette page n'existe pas ! Retournez à la <Link to='/'>page principale</Link> ?</p>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
